<template>
    <div class="chart">
        
            <ClientLineChart/>
        
            
        
    </div>
</template>

<script>
import ClientLineChart from './ClientLineChart'
export default {
    components:{
        ClientLineChart
    },
    name:"ClientChart",
}
</script>

<style scoped>
.Chart {
    padding: 20px;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, .4);
    border-radius: 20px;
    margin: 50px 0;
  }
</style>