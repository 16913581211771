<template>
  <div>
    <div class="row">
      <div class="col-xl-6 ">
        <div class="card card-custom bg-primary card-stretch gutter-b wid">
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-white">
              <i class="fas fa-check-circle text-white" style="font-size: 20px"> </i>
            </span>
            <span
              class="card-title font-weight-bolder text-white font-size-h2 mb-0 mt-6 d-block"
              >{{completedOrders}}</span
            >
            <span class="font-weight-bold text-white font-size-sm">{{$t('CompletedOrders')}}</span>
          </div>
        </div>
      </div>
      

      <div class="col-xl-6">
        <div class="card card-custom bg-primary card-stretch gutter-b wid">
          <div class="card-body">
            <span class="svg-icon svg-icon-2x svg-icon-white">
              <i
                class="fas fa-ban text-white"
                style="font-size: 20px"
              ></i>
            </span>
            <span
              class="card-title font-weight-bolder text-white font-size-h2 mb-0 mt-6 d-block"
              >{{cancelledOrders}}</span
            >
            <span class="font-weight-bold text-white font-size-sm">{{$t('CancelledOrders')}}</span>
          </div>
        </div>
      </div>

      <div class="col-xl-12">
         

           <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              {{$t('CompletedOrders')}} vs. {{$t('CancelledOrders')}}
            </div>
          </div>
          <div class="card-body">
            <div>
                  <ClientChart/>
                </div>
            
											
          </div>
        </div>
        
        
      </div>
    </div>
  </div>
</template>

<script>

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ClientChart from '../../Charts/Clientchart'




export default {
  data: function(){
    return{
      completedOrders:'',
      cancelledOrders:'',
      user: "",
      data:'',
      
    }
  },

  components:{
    ClientChart
  },
  beforeMount(){
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard", route: "" }]);
    //console.log(this.cuser)
    /*if(this.cuser.length !==0){
      this.data = this.cuser
    }
    

    if(this.cuser.length === 0){
      let local = JSON.parse(localStorage.getItem('op')).data
      //console.log(JSON.parse(localStorage.getItem('op')).data)
      this.data = local
      
    }


    if(this.data !==null){
      this.completedOrders = this.data.completed_orders;
   this.cancelledOrders = this.data.failed_orders;
    }*/
  this.user = JSON.parse(localStorage.getItem('client'))
  //console.log(this.user)
    this.$store.dispatch('clients/dashboard',this.user).then(res=>{
      //console.log(res.data)
      this.completedOrders = res.data.CompletedOrders.length
      this.cancelledOrders = res.data.FailedOrders.length
    })
   
    
  },
   
};
</script>

<style scoped>
.wid{
  padding: 30px;
}

@media only screen and (max-width: 600px) {
  .col-xl-6 , .col-xl-12{
  position: relative;
    width: 100%;
     padding-right: 0px; 
     padding-left: 0px;
}
}

</style>
