import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  async mounted () {
    this.user = JSON.parse(localStorage.getItem('client'))
    //console.log(this.user.id)

    var label =[]
    var orders = []
    await this.$store.dispatch('clients/chart',this.user.id).then((res)=>{
      //console.log(res.data.data.order)
      label = res.data.data.order.days
      orders =  res.data.data.order.count
    })

    this.renderChart({
      labels: label,
      datasets: [
        {
          label: 'orders',
           backgroundColor: [
                '#836BA8',
            ],
          
          data: orders
        },        
      ]
    }, {responsive: true, maintainAspectRatio: false})
  }
}